import React from 'react';
import { Link } from 'gatsby';
import { Container } from './nav.css';
import { isMobile } from "react-device-detect"
import Title from 'components/title';
// import copy from 'copy-to-clipboard';

const Nav = () => (
  <Container>
    <ul>
      <li>
        <Link to="/menu">Menu</Link>
      </li>
      <li>
        <a style={{textDecoration: "underline"}} href="https://www.google.com/maps/dir/?api=1&destination=Jacob's&destination_place_id=ChIJS8h8bx-shYAR3yaF4jWyekg">Directions</a>
      </li>
      <li>
      <Link to="/privacy">Privacy</Link>
      </li>
    </ul>
    {isMobile ? <div></div> : <div><div style={{ height: '1vh' }} /><a href="tel:+1707996">(707) 996-4024</a></div>}
  </Container>
);

export default Nav;
