import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Text = styled.span`
  display: block;
  font-weight: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return '400';
      default:
        return '500';
    }
  }};
  font-size: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return '2.8rem';
      case 'small':
        return '2.1rem';
      case 'address':
        return '1.7rem';
      case 'phone':
        return '1.7rem';
      default:
        return '3.4rem';
    }
  }};
  color: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return;
      case 'phone':
        return 'rgb(117, 117, 117, 1.0)'
      case 'small':
        return;
      case 'address':
        return;
      default:
        return 'rgb(143, 29, 19, 1.0)';
    }
  }};
  font-family: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return "Montserrat";
      case 'address':
        return "Montserrat";
      case 'small':
        return "Montserrat";
      case 'phone':
        return "Montserrat"
      default:
        return 'Lobster';
    }
  }};
  text-decoration: ${({ size }) => () => {
    switch (size) {
      case 'phone':
        return "none";
      default:
        return 'none';
    }
  }};
  line-height: 1.2;

  ${MEDIA.TABLET`
    font-size: ${({ size }) => () => {
      switch (size) {
        case 'large':
          return '2.6rem';
        case 'address':
          return '1.1rem';
        default:
          return '2rem';
      }
    }};
  `};
`;
